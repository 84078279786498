/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import necessary libraries.
import Config from '../../config'
import renderScreen from './render'
import {triggerEvent} from './event'
import {toCamelCase} from './helpers'

/**
 * Class to route request to appropriated screen.
 */
export default class Router {
	/**
	 * Initialize.
	 *
	 * @param   object   Renderer                 Component that renders screens.
	 * @param   string   defaultScreen            Default screen to render if root URL is requested?
	 * @param   string   defaultProps             Default properties to pass to screen renderer.
	 * @param   boolean  redirectToDefaultScreen  Whether to redirect to default screen if root URL is requested?
	 *
	 * @return  void
	 */
	static init(Renderer, defaultScreen, defaultProps, redirectToDefaultScreen = true) {
		// Store default screen and default properties for later use.
		this.default = {
			screen: defaultScreen,
			props: defaultProps,
			redirect: redirectToDefaultScreen
		}

		// Store React component for rendering requested screen.
		this.renderer = Renderer

		// Bind event handlers to correct context.
		this.route = this.route.bind(this)

		// Listen to history state change.
		window.addEventListener('popstate', this.route)

		// Initial route.
		this.route()
	}

	/**
	 * Route requested URL to appropriated screen.
	 *
	 * @param   mixed  custom  Pass boolean TRUE to force rendering default screen if no any screen detected.
	 *                         Pass a path to render a specific screen, e.g. /stores?orderBy=value.installDate
	 *
	 * @return  void
	 */
	static route(custom) {
		// Set custom URL if specified.
		if (typeof custom === 'string') {
			// Verify custom URL.
			if (custom.match(/^https?:/) && !custom.startsWith(Config.server_url)) {
				window.location.href = custom
				return
			}

			// Push custom URL to history state.
			custom = custom.replace(Config.server_url, '')
			window.history.pushState({}, '', (custom.startsWith('/') ? '' : '/') + custom)
		}

		// Parse location.
		const path = window.location.href.replace(Config.server_url, '')
		const props = Object.assign({}, this.default.props)

		if (path) {
			// Format: screen?name1=value1&name2=value2
			const screen = path.split('?')[0]

			// Get request parameters.
			const params = screen.length === path.length ? [] : path.split('?')[1].split('&')

			// Prepare properties to pass to the renderer.
			for (let i = 0; i < params.length; i++) {
				// Property to pass to the app in name=value format.
				const prop = params[i].split('=')

				// Convert property-name-format to propertyNameFormat.
				let name = toCamelCase(prop[0])

				// Refine value.
				const value = decodeURIComponent(prop[1])

				try {
					props[name] = JSON.parse(value)
				} catch (e) {
					if (value.match(/^(true|false)$/i)) {
						props[name] = (value === 'true' ? true : false)
					} else if (value.match(/^\d+$/i)) {
						props[name] = parseInt(value)
					} else {
						props[name] = value
					}
				}

				if (prop[0].startsWith('filter_')) {
					props.filters = Object.assign(props.filters || {}, {
						[prop[0].substr(7)]: props[name]
					})

					delete props[name]
				}
			}

			if (screen) {
				// Trigger an event before routing.
				triggerEvent(document, 'route', {screen, props})

				// Render the requested screen.
				return renderScreen(this.renderer, screen, {...props, ref: undefined})
			}
		}

		// Render the default screen.
		if (this.default.redirect) {
			this.route(this.default.screen)
		} else {
			renderScreen(this.renderer, this.default.screen, {...props})
		}
	}
}
