/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import necessary libraries.
import React from 'react'
import ReactDOM from 'react-dom'

/**
 * Function to render a screen.
 *
 * @param   object  Renderer
 * @param   string  screen
 * @param   object  props
 * @param   mixed   root
 *
 * @return  void
 */
export default function renderScreen(Renderer, screen, props, root) {
	// Query for root element if not provided.
	if (!root) {
		root = document.getElementById('root')
	}

	// Mount the Renderer.
	ReactDOM.render(<Renderer screen={screen} {...props} />, root)
}
