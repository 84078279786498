/**
 * @version     $Id$
 * @package     PageFly_Link_Generator
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import necessary libraries.
import './render.css'
import React from 'react'
import Config from '../config'
import NavBar from '../shared/components/navbar'
import {translate} from '../shared/includes/helpers'

/**
 * React component to render a requested screen.
 */
export default class Renderer extends React.Component {
	/**
	 * Constructor.
	 *
	 * @param   object  props
	 *
	 * @return  void
	 */
	constructor(props) {
		super(props)

		// Set initial state.
		this.state = {
			screen: this.props.screen,
			renderers: {}
		}
	}

	/**
	 * Handle changes in props.
	 *
	 * @param   object  props
	 * @param   object  state
	 *
	 * @return  mixed
	 */
	static getDerivedStateFromProps(props, state) {
		const newState = {}

		for (let p in props) {
			if (JSON.stringify(props[p]) !== JSON.stringify(state[p])) {
				newState[p] = props[p]
			}
		}

		if (JSON.stringify(newState) !== '{}') {
			return newState
		}

		return null
	}

	/**
	 * Render the requested screen.
	 *
	 * @return  mixed
	 */
	render() {
		// Get screen renderer.
		const {renderers, screen} = this.state
		const Renderer = renderers[screen]

		// Dynamically load the requested renderer once.
		if (Renderer === undefined) {
			import('../screens/' + screen)
				.then(module => {
					// Store screen renderer.
					renderers[screen] = module.default || null

					// Then, update the renderer.
					this.setState({ renderers })
				})
				.catch(error => {
					// Indicate that screen not found.
					renderers[screen] = error

					// Then, update the renderer.
					this.setState({ renderers })
				})
		}

		// Get login data.
		const loginData = JSON.parse(localStorage.getItem('pf_adm_csl'))

		// Prepare logged account menu.
		const right = []

		if (loginData) {
			right.push({
				name: 'account',
				link: '/profile',
				text: loginData.account.name,
				dropdown: [
					{
						name: 'profile',
						link: '/profile',
						text: 'Profile'
					},
					{
						name: 'logout',
						link: '/logout',
						text: 'Logout'
					}
				]
			})
		}

		// Start rendering.
		return (
			<div id="pagefly-admin-console">
				{screen !== 'short-link-builder' && <NavBar
					root={Config.server_url}
					brand={translate('PageFly Admin Console')}
					left={['install', 'login'].indexOf(screen) > -1 ? [] : Config.screens}
					right={['install', 'login'].indexOf(screen) > -1 ? [] : right}
					active={['install', 'login'].indexOf(screen) > -1 ? '' : screen}
				/>}
				{Renderer === undefined ? (
					<div className="d-flex justify-content-center align-items-center overlay-backdrop">
						<i className="fa fa-2x fa-spin fa-spinner"/>
					</div>
				) : Renderer instanceof Error ? (
					<div className="alert alert-danger">
						{Renderer.toString()}
					</div>
				) : (
					<Renderer {...this.props} />
				)}
			</div>
		)
	}
}
