/**
 * @version     $Id$
 * @package     PageFly_Link_Generator
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import necessary libraries.
import Config from './config'
import Renderer from './bootstrap/render'
import Router from './shared/includes/router'

// Instantiate router.
Router.init(Renderer, Config.default_screen, {
	server: Config.server_url
})
