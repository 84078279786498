/**
 * @version     $Id$
 * @package     PageFly_Admin_Console
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Define global configuration.
const Config = {
	/**
	 * General settings.
	 */
	server_url: 'https://pagefly.link/',
	/**
	 * Short link service settings.
 	 */
	short_link_service_url: 'https://pagefly.link/',
	// Client ID and API key from the Google's Developer Console.
	google_client_id: '115845069869-spl43rl45ge33h24qgu64q3vbm3kso9u.apps.googleusercontent.com',
	google_api_key: 'AIzaSyDjYMPmJblrP6oNG749ewmMQgtDmC-EvdQ',
	// Define default UTM data.
	utm_campaign: [
		{
			value: 'guides',
			label: 'guides'
		},
		{
			value: 'manual',
			label: 'manual'
		},
		{
			value: 'video-tutorials',
			label: 'video-tutorials'
		},
		{
			value: 'blog',
			label: 'blog'
		},
		{
			value: 'website',
			label: 'website'
		},
		{
			value: 'app-listing',
			label: 'app-listing'
		},
		{
			value: 'app-providers',
			label: 'app-providers'
		}
	],
	utm_source: [
		{
			value: 'profile:shopify-forum',
			label: 'profile:shopify-forum'
		},
		{
			value: 'profile:reddit',
			label: 'profile:reddit'
		},
		{
			value: 'profile:quora',
			label: 'profile:quora'
		},
		{
			value: 'community',
			label: 'community'
		},
		{
			value: 'website',
			label: 'website'
		},
		{
			value: 'guides',
			label: 'guides'
		},
		{
			value: 'video-guides',
			label: 'video-guides'
		},
		{
			value: 'manual',
			label: 'manual'
		},
		{
			value: 'blog',
			label: 'blog'
		},
		{
			value: 'video-tutorials',
			label: 'video-tutorials'
		},
		{
			value: 'app-providers',
			label: 'app-providers'
		},
		{
			value: 'support',
			label: 'support'
		},
		{
			value: 'nuturing',
			label: 'nuturing'
		},
		{
			value: 'app',
			label: 'app'
		}
	],
	utm_medium: [
		{
			value: 'message',
			label: 'message',
			require: {
				utm_source: ['profile:shopify-forum', 'profile:reddit', 'profile:quora']
			}
		},
		{
			value: 'signature',
			label: 'signature',
			require: {
				utm_source: ['profile:shopify-forum', 'profile:reddit', 'profile:quora']
			}
		},
		{
			value: 'information',
			label: 'information',
			require: {
				utm_source: ['profile:shopify-forum', 'profile:reddit', 'profile:quora']
			}
		},
		{
			value: 'fb-group',
			label: 'fb-group',
			require: {
				utm_source: ['community']
			}
		},
		{
			value: 'mainsite',
			label: 'mainsite',
			require: {
				utm_source: ['website']
			}
		},
		{
			value: 'academy',
			label: 'academy',
			require: {
				utm_source: ['website']
			}
		},
		{
			value: 'help-center',
			label: 'help-center',
			require: {
				utm_source: ['website']
			}
		},
		{
			value: 'blog',
			label: 'blog',
			require: {
				utm_source: ['website']
			}
		},
		{
			value: 'article',
			label: 'article',
			require: {
				utm_source: ['guides', 'manual']
			}
		},
		{
			value: 'content',
			label: 'content',
			require: {
				utm_source: ['video-guides', 'video-tutorials']
			}
		},
		{
			value: 'comment',
			label: 'comment',
			require: {
				utm_source: ['video-guides']
			}
		},
		{
			value: 'toc',
			label: 'toc',
			require: {
				utm_source: ['manual']
			}
		},
		{
			value: 'post',
			label: 'post',
			require: {
				utm_source: ['blog']
			}
		},
		{
			value: 'welcome',
			label: 'welcome',
			require: {
				utm_source: ['support']
			}
		},
		{
			value: 'chatbot',
			label: 'chatbot',
			require: {
				utm_source: ['support']
			}
		},
		{
			value: 'messages',
			label: 'messages',
			require: {
				utm_source: ['support']
			}
		},
		{
			value: 'email',
			label: 'email',
			require: {
				utm_source: ['nuturing']
			}
		},
		{
			value: 'notification',
			label: 'notification',
			require: {
				utm_source: ['app']
			}
		},
		{
			value: 'help',
			label: 'help',
			require: {
				utm_source: ['app']
			}
		},
		{
			value: 'dashboard',
			label: 'dashboard',
			require: {
				utm_source: ['app']
			}
		},
		{
			value: 'settings',
			label: 'settings',
			require: {
				utm_source: ['app']
			}
		},
		{
			value: 'sidebar',
			label: 'sidebar',
			require: {
				utm_source: ['app']
			}
		},
		{
			value: 'page-list',
			label: 'page-list',
			require: {
				utm_source: ['app']
			}
		},
		{
			value: 'page-editor',
			label: 'page-editor',
			require: {
				utm_source: ['app']
			}
		}
	],
	utm_content: [
		{
			value: 'welcome',
			label: 'welcome',
			require: {
				utm_source: ['community'],
				utm_medium: ['fb-group']
			}
		},
		{
			value: 'post',
			label: 'post',
			require: {
				utm_source: ['community'],
				utm_medium: ['fb-group']
			}
		},
		{
			value: 'mainmenu',
			label: 'mainmenu',
			require: {
				utm_source: ['website'],
				utm_medium: ['mainsite', 'academy', 'help-center']
			}
		},
		{
			value: 'homepage',
			label: 'homepage',
			require: {
				utm_source: ['website'],
				utm_medium: ['mainsite', 'academy', 'help-center']
			}
		},
		{
			value: 'guide',
			label: 'guide',
			require: {
				utm_source: ['website'],
				utm_medium: ['academy']
			}
		},
		{
			value: 'article',
			label: 'article',
			require: {
				utm_source: ['website'],
				utm_medium: ['academy', 'help-center']
			}
		},
		{
			value: 'description',
			label: 'description',
			require: {
				utm_source: ['video-guides'],
				utm_medium: ['content']
			}
		},
		{
			value: 'card',
			label: 'card',
			require: {
				utm_source: ['video-guides', 'video-tutorials'],
				utm_medium: ['content']
			}
		},
		{
			value: 'in-app',
			label: 'in-app',
			require: {
				utm_source: ['app-providers']
			}
		},
		{
			value: 'blog',
			label: 'blog',
			require: {
				utm_source: ['app-providers']
			}
		},
		{
			value: 'getting-started',
			label: 'getting-started',
			require: {
				utm_source: ['app'],
				utm_medium: ['sidebar']
			}
		},
		{
			value: 'help-options',
			label: 'help-options',
			require: {
				utm_source: ['app'],
				utm_medium: ['sidebar']
			}
		},
		{
			value: 'element-catalog',
			label: 'element-catalog',
			require: {
				utm_source: ['app'],
				utm_medium: ['page-editor']
			}
		},
		{
			value: 'element-inspector',
			label: 'element-inspector',
			require: {
				utm_source: ['app'],
				utm_medium: ['page-editor']
			}
		},
		{
			value: 'page-canvas',
			label: 'page-canvas',
			require: {
				utm_source: ['app'],
				utm_medium: ['page-editor']
			}
		}
	],
	/**
	 * Screen settings.
 	 */
	screens: [],
	default_screen: ''
}

export default Config
